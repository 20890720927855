<template>
  <div class="layout--full-page">
    <front-header />
    <router-view></router-view>
    <!-- <front-footer /> -->
  </div>
</template>

<script>
import FrontFooter from "@/layouts/components/FrontFooter.vue";
import FrontHeader from "@/layouts/components/FrontHeader.vue";

export default {
  components: {
    FrontFooter,
    FrontHeader
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  beforeDestroy() {},
  mounted() {}
};
</script>
